.KycFrom h4 {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.KycFrom p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.KycFrom small {
    color: #fff;
    font-size: 12px;
    display: block;
    text-align: center;
    margin-bottom: 30px;
    opacity: 0.7;
    FONT-VARIANT: JIS04;
    FONT-VARIANT: JIS04;
}

.KycFrom .form-label {
    color: #fff;
    font-size: 12px;
}