.ProfileInfo {
    position: relative;
    text-align: center;
    margin: 30px 0;
}

.ProfileInfo span {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto 10px;
    position: relative;
}

.ProfileInfo span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ProfileInfo small {
    font-size: 12px;
    color: #fff;
    display: block;
    opacity: 0.8;
    font-weight: 600;
    margin-bottom: 10px;
}

.ProfileInfo h6 {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    gap: 10px;
}

.ProfileInfo h6 a {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileInfo h6 a svg {
    width: 100%;
    height: 100%;
}

.btn-Wallet {
    display: block;
    padding: 7px 12px;
    font-size: 16px;
}

.btn-Wallet svg {
    font-size: 23px;
}

.referCashBox {
    color: #fff;
    padding: 0;
    margin: 0;
    list-style: none;
}

.referCashBox li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
}

.referCashBox li i {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: #fff;
    border-radius: 100%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.referCashBox li h6 {
    font-size: 12px;
    color: #ffffff96;
    font-weight: 600;
}

.referCashBox li i img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.referCashBox li h6 span {
    display: block;
    font-size: 16px;
    margin-top: 5px;
    color: #fff;
}

.referCashBox .form-label {
    font-size: 12px;
}

.referCashBox .form-control {
    background: transparent;
    color: #fff;
    border: navajowhite;
    border-bottom: 1px solid #fff5;
    border-radius: 0;
    padding: 0;
    height: auto;
}

.referCashBox .d-flex a {
    color: #fff;
    font-size: 20px;
    margin-left: 3px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.referCashBox li:nth-last-child(1) {
    margin: 0;
}

.btn-red {
    background: #d51d1d !important;
    color: #fff !important;
    border-radius: 4rem;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 600;
}

.btn-red svg {
    font-size: 22px;
}

.AddahrProfile {
    padding-top: 15px;
}

.AddahrProfile h6 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
}


form.emailUpdate {
    display: none;
}

form.emailUpdate.show {
    display: block;
}

.ProfileInfo span a {
    position: absolute;
    bottom: 0;
    right: 10px;
    background: #fff;
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}

.btn-green:disabled {
    background: #25b4a194;
    color: #fff;
    border: transparent;
    height: 38px;
}

.btn-email {
    height: 38px;
    white-space: nowrap;
}

.ChooseOption {
    display: flex;
    align-items: center;
    gap: 10px;
}

.w-50Image {
    width: 50%;
    position: relative;
}

.w-50Image span {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 100%;
    margin: 0 auto;
    margin-bottom: 10px;
    background: #000;
    color: #fff;
    font-size: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w-50Image span img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.w-50Image h6 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

label.w-50Image input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}

.w-25Image img {
    width: 60px;
    height: 60px;
    margin: 6px;
}

 .dialog-box {
    overflow: auto;
    max-width: 480px;
    background: #000;
    position: absolute;
    bottom: 20%;
    padding: 40px;
    border: 1px solid #000;
    border-radius: 1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.box{
    margin: 0 auto;
    max-width: 320px;
}

.blur-background {
    backdrop-filter: blur(1px);
    background-color: rgba(245, 241, 241, 0.932);
    position: fixed;
    left: 0;
    width: 100%;
    height: fit-content;
    z-index: 1;
    border-radius: 1rem;
  }
  
  