.NotificationPageBody {
    width: 100%;
    text-align: center;
}

.NotificationPageBody img {
    width: 35%;
    margin-bottom: 30px;
}

.NotificationPageBody h6 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.NotificationPageBody p {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    opacity: 0.7;
    letter-spacing: 0.3px;
}