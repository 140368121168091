.transactionsBody {
    text-align: center;
}

.transactionsBody img {
    width: 100%;
    max-width: 330px;
    margin-bottom: 40px;
}

.transactionsBody h6 {color: #fff;font-size: 18px;font-weight: 500;text-transform: capitalize;margin-bottom: 10px;}

.transactionsBody p {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}
.pagination {
    margin: 30px 0;
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 5px;
}

.pagination a.page-link {
    border-radius: 100% !important;
    width: 35px;
    height: 35px;
    background: #fff0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: initial;
    color: #fff;
    border: none;
    transition: all 0.5s;
}

.pagination a.page-link:hover{
    color: #fff;
    background: #24af9d;
}
.active>.page-link, .page-link.active{
    color: #fff;
    border: none;
    border-radius: 100% !important;
    width: 35px;
    height: 35px;
    background: #24af9d;
}
.page-link:focus {
    box-shadow: none;
}

.GameTransctionItem {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff4;
    padding-bottom: 15px;
}

.GameTransctionTime {
    width: 50px;
    min-width: 50px;
    text-align: center;
}

.GameTransctionTime h6 {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 3px;
}

.GameTransctionTime small {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    display: block;
    opacity: 0.8;
}

.GameTransctionInfo {
    display: flex;
    align-items: center;
    border-left: 1px solid #fff;
    padding-left: 15px;
}

.GameTransctionInfo small{
    font-size: 10px;
    color: #fff;
    display: block;
    text-align: end;
    opacity: 0.8;
    font-weight: 100;
}
.GameTransctionInfo h6 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: start;
}


.GametransctionCash p {
    font-size: 14px;
    color: #fff;
    margin: 0;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 500;
}

.GametransctionCash small {
    font-size: 10px;
    color: #fff;
    display: block;
    text-align: end;
    opacity: 0.8;
    font-weight: 100;
}

.GametransctionCash {
    min-width: 120px;
    margin-left: auto;
}

.GametransctionCash p svg {
    font-size: 20px;
    margin-right: 5px;
}
.GameTransctionInfo a {
    display: block;
    color: #fff !important;
    margin: 0 0 0 -9px;

}

.GameTransctionInfo h6 small {
    display: block;
    text-align: left;
    margin-top: 4px;
}

.green {
    color: #2ced2c;
}
.red{
    color: #ff3a3a;
}
.RedTag{
    display: block;
    font-style: normal;
    color: #fff;
    background-color: #ff3a3a;
    padding: 4px  12px;
}
.greenTag{
    display: block;
    font-style: normal;
    color: #fff;
    background-color: #0db25b;
    padding: 4px  12px;
}
.RedTag{
    display: block;
    font-style: normal;
    color: #fff;
    background-color: #E90042;
    padding: 4px  12px;
}
.YellowTag{
    display: block;
    font-style: normal;
    color: #fff;
    background-color: #f1c40f;
    padding: 4px  12px;
}
.Popupp {
    position: fixed;
    bottom: 0;
    z-index: 999;
    left: 0;
    background: #fff;
    max-width: 480px;
    width: 100%;
    padding: 20px;
    text-align: center;
    transform: translateY(100%);
    transition: all 0.5s;
}

.Popupp.show {
    transform: translateY(0);
}

.Popupp h3 {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #0001;
}

.Popupp ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.Popupp ul li {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 12px;
}

.Popupp ul li strong {
    margin-right: 20px;
    width: 40%;
}

li.Border-1 {
    border: 1px solid #0001;
    padding: 8px;
    border-radius: 7px;
}

li.Border-1 strong {
    width: auto !important;
}

.no-data .h6 {
    color: white;
}
.no-data .p {
    color: white;
}
.active>.page-link{
    padding-top: 7px;
}
@media (max-width: 767px){
.GameTransctionItem {
    gap: 2px;
}
}