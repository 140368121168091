.RoomCodePlayeriNfo .GameRunningBody + p {
    font-size: 14px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.RoomCodePlayeriNfo .GameRunningBody {
    margin-bottom: 10px;
}

.RoomCodePlayeriNfo .GameRunningBody + p svg {
    font-size: 19px;
}

.RoomCodeBox {
    background: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
}

.RoomCodeBox h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.RoomCodeBox h3 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
}

.RoomCodeBox a {
    font-size: 12px;
}

.GameRulesBox {
    background: #fff3;
    border-radius: 10px;
    padding: 15px;
}

.GameRulesBox h6 {
    color: #fff;
    font-size: 16px;
    text-align: center;
}

.GameRulesBox ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.GameRulesBox ul li {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 4px;
}

.matchStatus {
    position: relative;
}

.matchStatus h6 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 10px;
}

.matchStatus p {
    font-size: 12px;
    color: #fff;
    font-weight: 400;
}

.radioFlex {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    font-size: 14px;
    gap: 15px;
}

.form-check-input:checked {
    background: #22b29f;
    border-color: #22b29f;
}
.RoomCodeFlexWrap{
    display: flex;
    flex-wrap: wrap;
    margin: auto 10px ;
}
.RoomCodeFlexWrap input[type="file"]{
    margin: auto 10px ;
}
.RoomCodeFlexWrap textarea {
    width: 100%;
    overflow: auto;
    resize: none;
    background: #fff2;
    border: 1px solid #fff6;
    margin-top: 10px;
    color: #fff;
    padding: 10px;
}
.RoomCodeFlexWrap div {
    display: block;
    width: 100%;
    margin-top: 10px;
}
.RoomCodeFlexWrap  img{
    width: 40%;
    height: 90%;
}