.ChooseBank {
    position: relative;
}

.ChooseBank a {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #fff;
    border-radius: 8px;
    padding: 12px;
    gap: 12px;
    color: #fff;
}

.ChooseBank a span {
    width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
}

.ChooseBank a span img, .ChooseBank a span svg {
    width: 100%;
    height: 100%;
}

.ChooseBank a h6 {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}

.ChooseBank a h6 small {
    display: block;
    font-size: 10px;
    font-weight: 500;
}

.upiBox {
    background: #fff2;
    border: 1px solid #fff;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
}

.upiBox h6 {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    gap: 8px;
}

.upiBox h6 span {
    width: 50px;
    display: block;
}

.upiBox h6 span img {
    width: 100%;
    height: 100%;
}

.upiBox i {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 7px;
}

.upiBox i svg {
    width: 100%;
    height: 100%;
}

.upiBox p {
    font-size: 14px;
    color: #fff;
    margin-bottom: 0;
    font-weight: 400;
}

.upiBox small {
    display: block;
    margin-top: 3px;
}