.SupportBody {
    width: 100%;
    text-align: center;
}

.SupportBody img {
    width: 55%;
    margin-bottom: 20px;
}

.SupportBody h6 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 2;
}

.BtnWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-wrap: wrap;
}

.BtnWrap a,
.BtnWrap button {
    width: calc(50% - 8px);
}

.Btn-White {
    background: #fff;
    display: flex;
    align-items: center;
    gap: 7px;
    width: 50%;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 0;
}

.Btn-White svg {
    font-size: 20px;
}

.Btn-White:hover {
    background: #fff;
}

.supportChat .btn-instagram {
    background: linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%);
    color: #fff;
}
.supportChat .btn-outline-green{
    background: #fff;
    color: #000;
}
.supportChat .btn-green{
    font-size: 16px;
}
