.TermsBody {
    position: relative;
    color: #000;
}

.TermsBody h1 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
}

.TermsBody nav {
    background: #fff;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.TermsBody nav ol {
    margin: 0;
}

.TermsBody h4 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 25px;
}

.TermsBody p {
    font-size: 13px;
    font-weight: 400;
    opacity: 0.8;
}

.TermsBody ul li {font-size: 13px;font-weight: 400;opacity: 0.8;}
.TermsBody h4 strong {
    font-weight: 500;
}