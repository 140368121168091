.ReferBody img {
    width: 35%;
}

.earnInfo {
    margin: 30px 0 0;
}

.ReferBody {
    text-align: center;
}

.earnInfo h3 {
    font-size: 26px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 8px;
}

.earnInfo p {
    font-size: 16px;
    color: #fff;
    font-weight: 100;
    margin-bottom: 23px;
}

.earnInfo h5 {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.earnInfo h6 {
    font-size: 14px;
    color: #fff;
    font-weight: 300;
}

.earnInfo h6 strong {
    font-weight: 600;
    font-size: 15px;
}

.earnInfo h5 input {
    margin-left: 2px;
    width: 80px;
    text-align: left;
    background: transparent;
    color: #fff;
    border: none;
}

.earnInfo h5 a {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.referrules h4 {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
}

.referrules ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.referrules ul li {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 15px;
    margin-bottom: 12px;
}

.referrules ul li span {
    width: 80px;
    min-width: 80px;
}

.referrules ul li h6 {
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    margin: 0;
}

.referrules ul li h6 i {
    display: block;
    color: #7ff1b5;
    font-style: normal;
    margin-top: 7px;
}

.ReferBody h6 {
    color: #fff;
}
.mx-50{
    max-width: 280px;
}