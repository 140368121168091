.noRadius {
    border-radius: 6px;
}

.GameListMain {
    position: relative;
}

.GameListHeader {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.GameListHeader h6 {
    margin: 0;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
}

.GameListHeader h6 span {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GameListHeader h6 span img {
    width: 100%;
    height: 100%;
}

.GameListHeader a {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    opacity: 0.7;
}

.GameListItem {
    position: relative;
    margin-bottom: 20px;
    background: #ffffff1c;
    box-shadow: 0 0px 0 0.5px #ffffff9e;
    border-radius: 0.4rem;
    padding: 5px 10px;
}

.GameListItem h6 {
    border-bottom: 1px solid #ffffff9e;
    /* margin-bottom: 4px;
    padding-bottom: 10px; */
    color: #ffffffba;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.GameListItem h6 span {
    color: #fff;
    font-weight: 600;
}

.GameListItemWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.GameListItemWrap .btn-gray{
    padding: 2px 12px;
}
.GameListItemWrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
}

.GameListItemWrap ul li small {
    font-size: 10px;
    color: #fff;
    font-weight: 500;
}

.GameListItemWrap ul li p {
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    font-weight: 600;
    margin: 0;
}

.btn-gray {
    background: #a5a5a5;
    color: #fff;
    padding: 5px 16px;
    border-radius: 7px;
    font-size: 12px;
}

.GameRunningHeader {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff9e;
    justify-content: space-between;
    padding-bottom: 1px;
    margin-bottom: 5px;
}

.GameRunningHeader h6 {
    border: navajowhite;
    padding: 0;
    margin: 5px 10px;
}

.GameRunningHeader .btn-green {
    padding: 4px 16px;
    font-size: 12px;
}

.GameRunningHeader .btn-green img {
    width: 17px;
    mix-blend-mode: color-dodge;
}

.GameRunningBody {
    display: flex;
    gap: 15px;
    align-items: center;
}

.GameProfile span {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: block;
    margin: 0 auto 4px;
}

.GameProfile {
    margin-left: 10px;
    margin-right: 10px;
    width: 120px;
}

.GameProfile span img {
    width: 21px;
    height: 21px;
    border-radius: 100%;
    object-fit: cover;
    background: #fff;
}

.GameProfile p {
    font-size: 12px;
    color: #fff;
    margin: 0;
    font-weight: 400;
    text-align: center;
}

.GameRunningBody i {
    max-width: 100px;
    display: block;
    margin: 0 auto;
    height: 55px;
}

.GameRunningBody i img {
    width: 100%;
    height: 100%;
}

.GameRunningHeader .btn-red {
    font-size: 12px;
    padding: 4px 16px;
    width: fit-content;
    border-radius: 7px;
}

.GameRunningHeader .btn-brightgreen {
    background: #0db25b;
    color: #fff;
    border-radius: 7px;
    padding: 2px 12px;
    border: 1px solid #0db25b;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.5s;
}

.btn-red {
    background: red;
    color: #fff;
    border-radius: 7rem;
    padding: 8px 22px;
    border: 1px solid red;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.5s;
}

.max-400 {
    max-width: 250px;
    margin: 0 auto;
}