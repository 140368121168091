
.AadharKYC h4 {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 75px;
}

.AadharKYC p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.AadharKYC small {
    color: #fff;
    font-size: 12px;
    display: block;
    text-align: center;
    margin-bottom: 30px;
    opacity: 0.7;
    FONT-VARIANT: JIS04;
    FONT-VARIANT: JIS04;
}
.AadharKYC .btn-green{
width: 100%;
margin-bottom: 10px;
margin-top: 10px;
}
.AadharKYC img{
width: 35px;
height: 35px;
margin-right: 15px;
}