.moneyWrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
}

.EarnMoney {
    background: #22b3a0;
}

.EarnMoney h6 {
    color: #fff;
}
.HeaderLoginWrap {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
    color: #24b09e;
}
a.menuIcon {
    color: #24b09e !important;
    font-size: 30px;
    margin-top: -5px;
    position: relative;
    z-index: 99999;
}

.loginbtn{
    border: 1px solid #fff4;
    width: 100%;
    color: #fff;
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    margin: 0;
}
.closeMenu{
    display: none;
}
.MainScreen.show  .closeMenu{
    display: block;
    margin-top: 5px;
}
.MainScreen.show  .MenuIconMenu{
    display: none;
} 
.MainScreen.show {
    overflow: hidden;
}
.MainScreen.show {
    padding: 0;
}

.MainScreen.show .MainScreenHeader {
    background: #fff;
    padding: 15px;
}