.MainScreen {
    height: 100vh;
    overflow: auto;
    max-width: 480px;
    /* margin: 0 auto; */
    background: #000;
    padding: 15px;
    position: relative;
}

.MainScreenHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    z-index: 9999;
}

.HproductCard {
    position: relative;
    background: #000;
    box-shadow: 0px 4px 95px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 15px;
    display: block;
    border: 1px solid #fff;
}
.HproductCardImg {
    width: 100%;
    height: 240px;
    border-radius: 15px;
    overflow: hidden;
}

.HproductCardImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.HproductCardInfo {
    padding-top: 15px;
}

.HproductCardInfo h6 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    color: #fff;
}

.HproductCardInfo h6 span {
    width: 25px;
    height: 25px;
    display: block;
}

.HproductCardInfo h6 span img {
    width: 100%;
    height: 100%;
}

.HproductCardInfo p {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 0 !important;
    font-weight: 500;color: #fff ;
    animation: example 1s infinite;
    opacity: 1;
}
@keyframes example {
    from {opacity: 1;}
    to {opacity: 0;}
  }
.HproductCardInfo p span {
    width: 12px;
    height: 12px;
    display: block;
    background: #2FC317;
    border-radius: 100%;
    margin-right: 8px;
}
.HproductCardInfo p span.red{
    background-color: #FF0000;
}
.LogoIcon {
    width: 35px;
    height: 35px;
}

.LogoIcon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.AddMoney {
    display: flex;
    background: #000;
    margin-left: auto;
    border-width: 1px;
    border-radius: 7px;
    border-style: solid;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0 0 0 10px;
    box-shadow: 0 0px 0 0.4px #fff;
}

.AddMoney span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #fff;
}

.AddMoney h6 {
    font-size: 14px;
    font-weight: 800;
    margin: 0 6px;
    color: #fff;
}

.AddMoney h6 small {
    display: block;
    font-size: 10px;
    font-weight: 700;
    margin-bottom: -3px;
    color: #fff;
}

.AddMoney i {
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    background: #e7e7e7;
    justify-content: center;
    font-size: 22px;
    color: #1f7c6f;
}
.MainScreen hr {
    border-color: #fff;
    margin: 10px auto;
}

.DashboardMain h4 {
    font-size: 26px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 1px;
}

.GameCard {
    border-radius: 10px;
    padding: 10px;
    margin: 0 0 30px 0px;
}

.GameCard .HproductCardImg {
    border-radius: 6px;
    height: 120px;
}
.DashboardMain p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 20px;
}
.VideoHelp p {
    border: 1px solid #fff4;
    width: 100%;
    color: #fff;
    padding: 15px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4rem;
    margin: 0;
}

.VideoHelp {
    width: 100%;
    margin-bottom: 20px;
    display: block;
}

.VideoHelp span {
    width: max-content;
    background: #000;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding: 0 8px;
    margin-left: 25px;
    margin-bottom: -10px;
    color: #fff;
    gap: 6px;
    z-index: 9;
    position: relative;
}

.RkLuDoInfo h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.RkLuDoInfo p {
    font-size: 13px;
    color: #ffffffb7;
}

.RkLuDoInfo p a {
    color: #25b4a1;
}

.TermsAccordion button {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: none !important;
}

.TermsAccordion button span {
    max-width: 140px;
    display: block;
}

.TermsAccordion  span img {
    width: 30%;
    height: 30%;
}

.TermsAccordion button i {
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
}

.TermsAccordion button[aria-expanded="true"] i {
    display: none;
}

.accordion-item:first-of-type .accordion-button {
    background: transparent;
}

.TermSLinkWrap {
    display: flex;
    align-items: center;
    gap: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}

.TermSLinkWrap li {
    width: calc(50% - 10px);
}

.accordion-body {
    border-top: 1px solid #0002;
}

.TermSLinkWrap li a {
    font-size: 13px;
    font-weight: 500;
}

.TermSLinkWrap li a:hover {
    color: #25b4a1;
}
.maintenance{
    text-align: center;
}
.maintenance h6{
    color: #1f7c6f;font-size: large;
}
.maintenance p{
    font-size:small;
}
.maintenance b{
    color: #1f7c6f;
    font-size: small;
}
.androidApp{
  background: #000;
  padding: 20px 30px;
  margin: 0%; 
}
.androidApp .btn-green{
    padding: 14px ;
    border-radius: 7px;
    font-size: large; 
    margin: 0%; 
}
.gametournament img{
    width: 20px;
    height: 20px;
}
@media (max-width:340px) {
    .col-lg-6.col-md-6.col-sm-6.col-6 {
        width: 100%;
    }

.GameCard .HproductCardImg {
    height: 180px;
}
}

.red{
    color: #ff3a3a;
}
.custom-note{
    color: #1f7c6f;
    text-align: center;
    /* width: fit-content; */
    font-weight: 500;
}