.loginPage {
    /* padding: 0 15px; */
    height: 100vh;
    position: relative;
    background: #000;
}

.loginPage .row {
    height: 100%;
    justify-content: center;
}

.Loginbox {
    justify-content: center;
    height: 100%;
    background: #000;
    align-items: center;
    display: flex;
    width: 100%;
    max-width: 480px;
    margin: 0;
    /* border: 1px solid #fff; */
    border-bottom: none;
    flex-direction: column;
    position: relative;
}

.Loginbox form {
    width: 100%;
    color: #fff;
    padding: 30px;
    position: relative;
    margin: auto 0;
}

.form-label {
    font-size: 14px;
    margin-bottom: 4px;
}

.form-control {
    background: #fff;
    border: 1px solid #fafafa;
    font-size: 14px;
    height: 38px;
    padding: 0 10px;
    font-weight: 400;
}

.OTPWrap {
    display: flex;
    gap: 10px;
}

.LoginBoxImage {
    width: 100%;
    height: 100%;
    position: relative;
}

.LoginBoxImage img {
    width: 100%;
    height: 100%;
}
.form-control:focus {
    border-color: #25b4a16b;
    box-shadow: none;
    background: #fafafa;
}

.Terms {
    margin-top: auto;
    font-size: 12px;
    color: #fafafa;
    text-align: center;
    font-weight: 500;
    max-width: 380px;
}

.Terms a {
    color: #25b4a1;
    text-decoration: underline;
}
.Loginbox form h6 {
    font-size: 26px;
    color: #fff;
    margin-bottom: 40px;
}

@keyframes splashAnim {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    to {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px)
    }
}
.LoginBoximage {
    width: 100%;
    height: 410px;
    margin-bottom: auto;
    position: absolute;
    top: 0;
    overflow: hidden;
    -webkit-animation: splashAnim 22s linear infinite alternate-reverse;
    animation: splashAnim 22s linear infinite alternate-reverse;
}

.LoginBoximage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LoginBoximage::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(179deg, #00000000, #000000);
}
.BackBtn {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.ResendOtp {
    position: relative;
    color: #25b4a1 !important;
    font-size: 14px;
    margin: 0 auto;
    display: block;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
}