.LinksOFTerms {
    padding: 0;
    list-style: none;
    margin: 0;
}

.LinksOFTerms li a {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 10px 5px;
    border-bottom: 1px solid #ffffff8c;
    display: block;
}